<template>
  <div id="panel" class="panel-cover" style="">
    <div class="panel-main">
      <div class="panel-main__inner panel-inverted">
        <div class="panel-main__content">
          <div class="ih-item circle effect right_to_left">
            <a class="blog-button">
              <div class="img">
                <img src="@/assets/img/one.jpg" alt="img"
                     class="js-avatar iUp profilepic header-logo">
              </div>
              <div class="info iUp">
                <div class="info-back">
                  <!-- 可以放个图片-->
                  <img alt="img" src="@/assets/img/two.jpg" class="js-avatar profilepic">
                  <!--<h2>Fighting</h2>
                                    <p>2023 · 突破</p> -->
                </div>
              </div>
            </a>
          </div>
          <h1 class="panel-cover__title panel-title iUp">
            <br/>NYG
          </h1>

          <hr class="panel-cover__divider iUp"/>

          <p class="panel-cover__subtitle panel-subtitle iUp">春风扔我在城关</p>

          <canvas class="navigation-wrapper iUp" id="canvas" style="width:50%;padding-top:0;" width="920"
                  height="350"></canvas>
<!--          人体时钟-->
<!--          <iframe src="https://renwai.ren/cdn/html/shizhong/" scrolling="no" frameborder="0" width="170px" height="60px"></iframe>-->

          <!--无法显示时显示的文字-->
          <p id="description" class="panel-cover__description iUp">{{ phrase }}
            <br/>
            <strong>-「{{ from }}」</strong>
          </p>
          <div class="navigation-wrapper iUp">
            <div>
              <nav class="cover-navigation cover-navigation--primary">
                <ul class="navigation">
                  <li class="navigation__item">
                    <a href="#" style="--clr:#1e9bff"><span>首页</span><i></i></a>
                  </li>
                  <li class="navigation__item">
                    <a href="#" style="--clr:#f85d5d"><span>博客</span><i></i></a>
                  </li>
                  <li class="navigation__item">
                    <a href="#" style="--clr:#ff1867"><span>简历</span><i></i></a>
                  </li>
                  <li class="navigation__item">
                    <a href="#" style="--clr:#ebe417"><span>关于</span><i></i></a>
                  </li>
                </ul>
              </nav>
            </div>

            <div class="iUp">
              <nav class="cover-navigation navigation--social">
                <ul class="navigation">
                  <li class="navigation__item">
                    <a href="javascript:void(0)" title="QQ" @click="qq()">
                      <svg t="1689766900753" class="icon" viewBox="0 0 1024 1024" version="1.1"
                           xmlns="http://www.w3.org/2000/svg" p-id="987" width="32" height="32">
                        <path
                            d="M849.621 619.904a1364.352 1364.352 0 0 0-28.8-80.47l-38.826-95.829c0-1.109 0.512-19.968 0.512-29.696 0-163.882-78.208-328.576-270.507-328.576S241.493 250.027 241.493 413.867c0 9.77 0.47 28.629 0.512 29.738l-38.826 95.83a1398.485 1398.485 0 0 0-28.8 80.469c-36.694 116.779-24.79 165.12-15.744 166.187 19.413 2.304 75.562-87.894 75.562-87.894 0 52.224 27.179 120.406 86.016 169.643-21.973 6.699-48.938 17.024-66.304 29.653-15.573 11.392-13.61 23.04-10.794 27.734 12.33 20.522 211.413 13.098 268.928 6.698 57.472 6.4 256.597 13.824 268.885-6.741 2.816-4.693 4.779-16.299-10.795-27.69-17.365-12.63-44.33-22.955-66.346-29.697 58.837-49.194 86.016-117.376 86.016-169.642 0 0 56.149 90.24 75.562 87.893 9.046-1.067 20.907-49.365-15.786-166.144"
                            fill="#e6e6e6" p-id="988"></path>
                      </svg>
                      <span class="label">QQ</span>
                    </a>
                  </li>
                  <li class="navigation__item">
                    <a @click="weiXin()" title="微信">
                      <svg t="1689766832048" class="icon" viewBox="0 0 1024 1024" version="1.1"
                           xmlns="http://www.w3.org/2000/svg" p-id="663" width="32" height="32">
                        <path
                            d="M670.023548 368.251062c10.259668 0 20.38119 0.787946 30.53648 1.902326-27.361161-127.435391-163.538657-222.082004-319.066873-222.082004-173.813674 0-316.202639 118.450762-316.202639 268.934079 0 86.831624 47.341215 158.128437 126.509299 213.512838l-31.583323 95.101985 110.560048-55.386448c39.524179 7.748475 71.228251 15.809057 110.716614 15.809057 9.930163 0 19.771299-0.451278 29.473265-1.187035-6.123464-21.147646-9.771551-43.274596-9.771551-66.340941C401.265478 480.380862 519.85234 368.251062 670.023548 368.251062L670.023548 368.251062zM500.047272 282.537911c23.887037 0 39.591717 15.70468 39.591717 39.470967 0 23.679305-15.705704 39.542598-39.591717 39.542598-23.627117 0-47.410799-15.863293-47.410799-39.542598C452.636472 298.17403 476.366943 282.537911 500.047272 282.537911L500.047272 282.537911zM278.751167 361.551476c-23.731494 0-47.619554-15.863293-47.619554-39.542598 0-23.76731 23.88806-39.470967 47.619554-39.470967s39.488363 15.636119 39.488363 39.470967C318.23953 345.688183 302.482661 361.551476 278.751167 361.551476L278.751167 361.551476zM278.751167 361.551476"
                            fill="#e6e6e6" p-id="664"></path>
                        <path
                            d="M958.709483 614.70822c0-126.403898-126.543068-229.42832-268.652669-229.42832-150.485363 0-268.915659 103.09503-268.915659 229.42832 0 126.753869 118.498858 229.462089 268.915659 229.462089 31.514761 0 63.272046-7.886621 94.89016-15.809057l86.690408 47.567365-23.76731-79.048357C911.351895 749.174748 958.709483 686.041872 958.709483 614.70822L958.709483 614.70822zM602.842473 575.132876c-15.70468 0-31.618115-15.633049-31.618115-31.618115 0-15.739473 15.914458-31.583323 31.618115-31.583323 24.010857 0 39.576367 15.84385 39.576367 31.583323C642.41884 559.499827 626.853329 575.132876 602.842473 575.132876L602.842473 575.132876zM776.78099 575.132876c-15.565511 0-31.445176-15.633049-31.445176-31.618115 0-15.739473 15.809057-31.583323 31.445176-31.583323 23.801079 0 39.576367 15.84385 39.576367 31.583323C816.356334 559.499827 800.581046 575.132876 776.78099 575.132876L776.78099 575.132876zM776.78099 575.132876"
                            fill="#e6e6e6" p-id="665"></path>
                      </svg>
                      <span class="label">微信</span>
                    </a>
                  </li>
                  <li class="navigation__item">
                    <!-- 加密的邮箱地址, 请自行修改, 或者改用下方的明文方式 -->
                    <a href="javascript:void(0)" @click="Email()" title="Email">
                      <!-- <a href="test@test.com" title="email"> -->
                      <svg t="1689767073467" class="icon" viewBox="0 0 1024 1024" version="1.1"
                           xmlns="http://www.w3.org/2000/svg" p-id="5586" width="32" height="32">
                        <path
                            d="M886.3 299.8l-381.1 220-371.6-214.6c3.6-56.2 50.3-100.7 107.4-100.7h538.3c55.3 0 100.9 41.6 107 95.3z m0.7 78.8v334.9c0 59.5-48.2 107.7-107.7 107.7H241c-59.5 0-107.7-48.2-107.7-107.7V384.3l354.9 204.9c12.2 7 26.9 5.7 37.5-2.1 0.5-0.2 0.9-0.5 1.4-0.8L887 378.6z"
                            p-id="5587" fill="#e6e6e6"></path>
                      </svg>
                      <span class="label">Email</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="panel-cover--overlay cover-slate"></div>
    </div>
    <div class="remark iUp">
      <p class="power">Copyright © 2022 - {{ new Date().getFullYear() }} NYG
        <span>
          <a href="https://beian.miit.gov.cn/" target="_blank">津ICP备2024021427号-1</a>
        </span>
        <span>&nbsp;&nbsp;</span>
        <span>
          <img src="@/assets/img/beian.png" class="beianimg" alt="img" />
          <a href="https://beian.mps.gov.cn/#/query/webSearch?code=13098102002404" rel="noreferrer" target="_blank">冀公网安备13098102002404</a>
        </span>
      </p>
    </div>

  </div>
</template>

<script>
import {clockInit} from "@/assets/js/clock"
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: 'HomeView',
  components: {},
  data() {
    return {
      NYGInfo: {
        email: "eWl3dXl1OThAZ21haWwuY29t",
        phone: "",
        QQ: "MjA3NDYyNjQ1NQ==",
      },
      phrase: "喝了这碗鸡血，干翻这个世界！",
      from: "NYG",

    }
  },
  mounted() {
    // 时间初始化
    clockInit();
    // 当前请求超时时间
    axios.defaults.timeout = 3000
    // 获取一言
    axios.get("https://v1.hitokoto.cn").then(resp => {
      if (resp.status === 200) {
        this.phrase = resp.data.hitokoto;
        this.from = resp.data.from;
      }
    }).catch(e => {
      console.log(e);
    });

  },
  methods: {
    decodingBase64(encoded) {
      return atob(encoded);
    },
    weiXin() {
      Swal.fire({
        title: "请使用微信扫一扫",
        text: "请使用微信扫一扫",
        confirmButtonText: "好的",
        imageUrl: require("@/assets/img/weixin.png"),
        imageWidth: 150,
        imageHeight: 150
      })
    },
    qq() {
      Swal.fire({
        title: "QQ号",
        text: this.decodingBase64(this.NYGInfo.QQ),
        confirmButtonText: "好的",
        imageWidth: 150,
        imageHeight: 150
      })
    },
    Email() {
      Swal.fire({
        title: "Email",
        text: this.decodingBase64(this.NYGInfo.email),
        confirmButtonText: "好的",
        imageWidth: 150,
        imageHeight: 150
      })
    }
  }
}
</script>

<style>
/*背景图片*/
#panel {
  background: url(@/assets/img/bj.jpg) center center / cover no-repeat rgb(102, 102, 102);
}
</style>
