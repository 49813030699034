export let clockInit = function () {
    const t = 820;
    const a = 250;
    const r = 7;
    const n = 10;
    const e = .65;
    let f;
    const o = [];
    const v = ["#33B5E5", "#0099CC", "#AA66CC", "#9933CC", "#99CC00", "#669900", "#FFBB33", "#FF8800", "#FF4444",
        "#CC0000"
    ];
    let h = [];
    const u = [
        [
            [0, 0, 1, 1, 1, 0, 0],
            [0, 1, 1, 0, 1, 1, 0],
            [1, 1, 0, 0, 0, 1, 1],
            [1, 1, 0, 0, 0, 1, 1],
            [1, 1, 0, 0, 0, 1, 1],
            [1, 1, 0, 0, 0, 1, 1],
            [1, 1, 0, 0, 0, 1, 1],
            [1, 1, 0, 0, 0, 1, 1],
            [0, 1, 1, 0, 1, 1, 0],
            [0, 0, 1, 1, 1, 0, 0]
        ],
        [
            [0, 0, 0, 1, 1, 0, 0],
            [0, 1, 1, 1, 1, 0, 0],
            [0, 0, 0, 1, 1, 0, 0],
            [0, 0, 0, 1, 1, 0, 0],
            [0, 0, 0, 1, 1, 0, 0],
            [0, 0, 0, 1, 1, 0, 0],
            [0, 0, 0, 1, 1, 0, 0],
            [0, 0, 0, 1, 1, 0, 0],
            [0, 0, 0, 1, 1, 0, 0],
            [1, 1, 1, 1, 1, 1, 1]
        ],
        [
            [0, 1, 1, 1, 1, 1, 0],
            [1, 1, 0, 0, 0, 1, 1],
            [0, 0, 0, 0, 0, 1, 1],
            [0, 0, 0, 0, 1, 1, 0],
            [0, 0, 0, 1, 1, 0, 0],
            [0, 0, 1, 1, 0, 0, 0],
            [0, 1, 1, 0, 0, 0, 0],
            [1, 1, 0, 0, 0, 0, 0],
            [1, 1, 0, 0, 0, 1, 1],
            [1, 1, 1, 1, 1, 1, 1]
        ],
        [
            [1, 1, 1, 1, 1, 1, 1],
            [0, 0, 0, 0, 0, 1, 1],
            [0, 0, 0, 0, 1, 1, 0],
            [0, 0, 0, 1, 1, 0, 0],
            [0, 0, 1, 1, 1, 0, 0],
            [0, 0, 0, 0, 1, 1, 0],
            [0, 0, 0, 0, 0, 1, 1],
            [0, 0, 0, 0, 0, 1, 1],
            [1, 1, 0, 0, 0, 1, 1],
            [0, 1, 1, 1, 1, 1, 0]
        ],
        [
            [0, 0, 0, 0, 1, 1, 0],
            [0, 0, 0, 1, 1, 1, 0],
            [0, 0, 1, 1, 1, 1, 0],
            [0, 1, 1, 0, 1, 1, 0],
            [1, 1, 0, 0, 1, 1, 0],
            [1, 1, 1, 1, 1, 1, 1],
            [0, 0, 0, 0, 1, 1, 0],
            [0, 0, 0, 0, 1, 1, 0],
            [0, 0, 0, 0, 1, 1, 0],
            [0, 0, 0, 1, 1, 1, 1]
        ],
        [
            [1, 1, 1, 1, 1, 1, 1],
            [1, 1, 0, 0, 0, 0, 0],
            [1, 1, 0, 0, 0, 0, 0],
            [1, 1, 1, 1, 1, 1, 0],
            [0, 0, 0, 0, 0, 1, 1],
            [0, 0, 0, 0, 0, 1, 1],
            [0, 0, 0, 0, 0, 1, 1],
            [0, 0, 0, 0, 0, 1, 1],
            [1, 1, 0, 0, 0, 1, 1],
            [0, 1, 1, 1, 1, 1, 0]
        ],
        [
            [0, 0, 0, 0, 1, 1, 0],
            [0, 0, 1, 1, 0, 0, 0],
            [0, 1, 1, 0, 0, 0, 0],
            [1, 1, 0, 0, 0, 0, 0],
            [1, 1, 0, 1, 1, 1, 0],
            [1, 1, 0, 0, 0, 1, 1],
            [1, 1, 0, 0, 0, 1, 1],
            [1, 1, 0, 0, 0, 1, 1],
            [1, 1, 0, 0, 0, 1, 1],
            [0, 1, 1, 1, 1, 1, 0]
        ],
        [
            [1, 1, 1, 1, 1, 1, 1],
            [1, 1, 0, 0, 0, 1, 1],
            [0, 0, 0, 0, 1, 1, 0],
            [0, 0, 0, 0, 1, 1, 0],
            [0, 0, 0, 1, 1, 0, 0],
            [0, 0, 0, 1, 1, 0, 0],
            [0, 0, 1, 1, 0, 0, 0],
            [0, 0, 1, 1, 0, 0, 0],
            [0, 0, 1, 1, 0, 0, 0],
            [0, 0, 1, 1, 0, 0, 0]
        ],
        [
            [0, 1, 1, 1, 1, 1, 0],
            [1, 1, 0, 0, 0, 1, 1],
            [1, 1, 0, 0, 0, 1, 1],
            [1, 1, 0, 0, 0, 1, 1],
            [0, 1, 1, 1, 1, 1, 0],
            [1, 1, 0, 0, 0, 1, 1],
            [1, 1, 0, 0, 0, 1, 1],
            [1, 1, 0, 0, 0, 1, 1],
            [1, 1, 0, 0, 0, 1, 1],
            [0, 1, 1, 1, 1, 1, 0]
        ],
        [
            [0, 1, 1, 1, 1, 1, 0],
            [1, 1, 0, 0, 0, 1, 1],
            [1, 1, 0, 0, 0, 1, 1],
            [1, 1, 0, 0, 0, 1, 1],
            [0, 1, 1, 1, 0, 1, 1],
            [0, 0, 0, 0, 0, 1, 1],
            [0, 0, 0, 0, 0, 1, 1],
            [0, 0, 0, 0, 1, 1, 0],
            [0, 0, 0, 1, 1, 0, 0],
            [0, 1, 1, 0, 0, 0, 0]
        ],
        [
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 1, 1, 0],
            [0, 1, 1, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 1, 1, 0],
            [0, 1, 1, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0]
        ]
    ];

    function l(t) {
        const a = [];
        f.fillStyle = "#005EAC";
        const r = new Date;
        let e = 70,
            o = 30;
        const v = r.getHours();
        let u = Math.floor(v / 10);
        let l = v % 10;
        a.push({
            num: u
        });
        a.push({
            num: l
        });
        a.push({
            num: 10
        });
        const c = r.getMinutes();
        u = Math.floor(c / 10);
        l = c % 10;
        a.push({
            num: u
        });
        a.push({
            num: l
        });
        a.push({
            num: 10
        });
        const M = r.getSeconds();
        u = Math.floor(M / 10);
        l = M % 10;
        a.push({
            num: u
        });
        a.push({
            num: l
        });
        for (let p = 0; p < a.length; p++) {
            a[p].offsetX = e;
            e = m(e, o, a[p].num, t);
            if (p < a.length - 1) {
                if (a[p].num !== 10 && a[p + 1].num !== 10) {
                    e += n
                }
            }
        }
        if (h.length === 0) {
            h = a
        } else {
            for (let C = 0; C < h.length; C++) {
                if (h[C].num !== a[C].num) {
                    s(a[C]);
                    h[C].num = a[C].num
                }
            }
        }
        i(t);
        g();
        return r
    }

    function s(t) {
        const a = t.num;
        const n = u[a];
        for (let e = 0; e < n.length; e++) {
            for (let f = 0; f < n[e].length; f++) {
                if (n[e][f] === 1) {
                    const h = {
                        offsetX: t.offsetX + r + r * 2 * f,
                        offsetY: 30 + r + r * 2 * e,
                        color: v[Math.floor(Math.random() * v.length)],
                        g: 1.5 + Math.random(),
                        vx: Math.pow(-1, Math.ceil(Math.random() * 10)) * 4 + Math.random(),
                        vy: -5
                    };
                    o.push(h)
                }
            }
        }
    }

    function i(t) {
        for (let a = 0; a < o.length; a++) {
            t.beginPath();
            t.fillStyle = o[a].color;
            t.arc(o[a].offsetX, o[a].offsetY, r, 0, 2 * Math.PI);
            t.fill()
        }
    }

    function g() {
        let n = 0;
        for (let f = 0; f < o.length; f++) {
            const v = o[f];
            v.offsetX += v.vx;
            v.offsetY += v.vy;
            v.vy += v.g;
            if (v.offsetY > a - r) {
                v.offsetY = a - r;
                v.vy = -v.vy * e
            }
            if (v.offsetX > r && v.offsetX < t - r) {
                o[n] = o[f];
                n++
            }
        }
        for (; n < o.length; n++) {
            o.pop()
        }
    }

    function m(t, a, n, e) {
        const f = u[n];
        for (let o = 0; o < f.length; o++) {
            for (let v = 0; v < f[o].length; v++) {
                if (f[o][v] === 1) {
                    e.beginPath();
                    e.arc(t + r + r * 2 * v, a + r + r * 2 * o, r, 0, 2 * Math.PI);
                    e.fill()
                }
            }
        }
        e.beginPath();
        t += f[0].length * r * 2;
        return t
    }

    const c = document.getElementById("canvas");
    c.width = t;
    c.height = a;
    f = c.getContext("2d");
    // var M = new Date;
    setInterval(function () {
        f.clearRect(0, 0, f.canvas.width, f.canvas.height);
        l(f)
    }, 50)
};